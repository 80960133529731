<div class="toolbar"
  role="banner">
  <a href="https://www.propiris.com"> <img style="padding: 15px"
      width="100"
      alt="Angular Logo"
      src="assets/logo512.png" />
  </a>
</div>
<br>
<br>
<br>
<div class="content">
  <mat-card>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Users Login Here">

        <div class="example-container">
          <br>

          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your email</mat-label>
              <input matInput
                placeholder="startyourproject@example.com"
                [formControl]="email"
                required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>

          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your password</mat-label>
              <input matInput
                [formControl]="password"
                [type]="hide ? 'password' : 'text'">
              <button mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="parent">
            <div>
              Forgot your password?<button mat-button color="primary">Reset password</button>
            </div>
        </div>
          <br>

          <div class="parent">
            <button mat-raised-button
              (click)="login()"
              color="primary">
              <mat-icon>person</mat-icon>
              Login
            </button>
          </div>
          <br>

          <div class="parent">
            <div>
              No account?<button routerLink="/createaccount" mat-button color="primary">Create Account</button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Contractors Login Here">
        <div class="example-container">
          <br>

          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your email</mat-label>
              <input matInput
                placeholder="startyourproject@example.com"
                [formControl]="email"
                required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your password</mat-label>
              <input matInput
                [type]="hide ? 'password' : 'text'">
              <button mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="parent">
            <div>
              Forgot your password?<button mat-button color="primary">Reset password</button>
            </div>
          </div>
          <br>

          <div class="parent">
            <button mat-raised-button
              color="primary">
              <mat-icon>person</mat-icon>
              Login
            </button>
          </div>
          <br>

          <div class="parent">
            <div>
              No account?<button mat-button routerLink="/createaccount" color="primary">Create Account</button>
            </div>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

<!-- <amplify-authenticator>
  <div>
    Restaurants App
    <amplify-sign-out></amplify-sign-out>
  </div>
</amplify-authenticator> -->