import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contractorprofile',
  templateUrl: './contractorprofile.component.html',
  styleUrls: ['./contractorprofile.component.css']
})
export class ContractorprofileComponent implements OnInit {
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  constructor() { }

  ngOnInit(): void {
  }

}
