<style>
    @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cabin:wght@600&family=Inconsolata:wght@200;300&family=Roboto:wght@300&display=swap');
    </style>
<div class="toolbar" role="banner">
    <a href="https://www.propiris.com/"> <img
     style="padding: 15px"
      width="100"
      alt="Angular Logo"
      src="assets/logo512.png"
    />
    </a>
    <div class="spacer"></div>
    <button  
        mat-raised-button routerLink="/createaccount" 
        
        color="primary"> 
            <mat-icon>fingerprint</mat-icon> 
            Create Account
    </button>
    <div style="padding: 0px 10px"></div>
    <button  
        mat-raised-button routerLink="/login" 
        
        color="primary"> 
            <mat-icon>person</mat-icon> 
            Login
            <!-- alt person build fingerprint -->
    </button>
    <div style="padding: 0px 15px"></div>
</div>

<div class = "content">
    <br>
    <br>
    <br>
    <p style="font-family: 'Bebas Neue', cursive; color: #126ab3; font-size: 72px;">IT'S AS EASY AS 1 2 3 ...</p>
    <div class="cards">
        <mat-card class="example-card">
        <mat-icon class="icon-number">looks_one</mat-icon>
        <mat-card-header>
            <div style="text-align:center;">
                <mat-card-title style="font-family: 'Bebas Neue', cursive; color: #126ab3; font-size: 36px; line-height: normal;"> 
                    Choose a Service
                </mat-card-title>
            </div> 
        </mat-card-header>

        <mat-icon class="icon-display">open_in_browser</mat-icon>
        <mat-card-content>
            <p style="font-family: 'Cabin', sans-serif; color: #126ab3; font-size: 24px; line-height: normal;">
                Whether you need your landscaping done or a room painted to make your house a home. Choose the service you need from the dozens offered on PROPiRIS.
            </p>
        </mat-card-content>
        </mat-card>
        <mat-card class="example-card">
            <mat-icon class="icon-number">looks_two</mat-icon>
            <mat-card-header>
                <div style="text-align:center;">
                    <mat-card-title style="font-family: 'Bebas Neue', cursive; color: #126ab3; font-size: 36px; line-height: normal; ">
                        Upload a Photo
                    </mat-card-title>
                </div>
            </mat-card-header>

            <mat-icon class="icon-display">add_a_photo</mat-icon>   

            <mat-card-content>
                <p style="font-family: 'Cabin', sans-serif; color: #126ab3; font-size: 24px; line-height: normal;">
                    Next, upload a couple quick pictures of the area or room needing work done along with a description of what you need as well as any customizations you may want.
                </p>
            </mat-card-content>
            </mat-card>
            <mat-card class="example-card">
                <mat-icon class="icon-number">looks_3</mat-icon>
                <mat-card-header>
                    <mat-card-title style="font-family: 'Bebas Neue', cursive; color: #126ab3; font-size: 30px; line-height: normal;">
                        Get an Instant Quote
                    </mat-card-title>
                </mat-card-header>

                <mat-icon class="icon-display">insert_invitation</mat-icon>    
                <mat-card-content>
                    <br>
                    <p style="font-family: 'Cabin', sans-serif; color: #126ab3; font-size: 26px; line-height: normal;">
                    Get an instant quote and schedule a delivery date from a professional near you.
                    </p>
                </mat-card-content>
                </mat-card>
    </div>
    <button  
        mat-raised-button routerLink="/createaccount" 
        
        color="primary"> 
            Start Your Home Project Now
            <!-- alt person build fingerprint -->
    </button>
</div>
