import { Component, OnInit, Input } from '@angular/core';

// import { Auth } from 'aws-amplify';
import {FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth';
import { GraphQLAPIClass } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';


// const oneTodo = API.graphql(graphqlOperation(queries.getTodo, { id: 'some id' }));





@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
 
  
  hide = true;
  email = new FormControl('', [Validators.required, Validators.email]);
  username = new FormControl('', [Validators.required]);
  phoneNumber = new FormControl('', []);
  password = new FormControl('', [Validators.required]);
  constructor(
    private router: Router,
  ) {};

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  signUp() {
    let actual_name = this.username.value as string;
    let username = this.email.value as string; 
    let password = this.password.value as string;
    let email = this.email.value as string;
    let phone_number = '+1'+ this.phoneNumber.value as string;
    try {
      Auth.signUp({ username, password, attributes: { email, phone_number } });
      this.router.navigate(['/user']);
  } catch (error) {
      console.log('error signing up:', error);
  }
    
  }
  

  ngOnInit(): void {
  }


}
