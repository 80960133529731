import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import { APIService } from '../API.service';

import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

Amplify.configure({
  Auth :{
    region: 'us-west-2',
    userPoolId: 'us-west-2_fzYPgwzTi',
    userPoolWebClientId: '7he5im2fft0vnqjp6jfac5831n',
    authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
})

@Component({
  
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.email]);
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  hide = true;
  constructor(
    private router: Router,
    private apiService: APIService,
  ) { 

  }
  
  ngOnInit(): void {
  }
  login() {
    let username = this.email.value as string; 
    let password = this.password.value as string;

    try {
      Auth.signIn( username, password );

      this.router.navigate(['/user']);
  } catch (error) {
      console.log('error signing up:', error);
  }
    
  }

  getUserProfile(): void {
    const allTodos = this.apiService.ListTodos();
    console.log(allTodos);
  }
} 
