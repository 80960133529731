<div class="toolbar"
role="banner">
<a href="https://www.propiris.com"> <img style="padding: 15px"
    width="100"
    alt="Angular Logo"
    src="assets/logo512.png" />
</a>
<div class="spacer"></div>
<button  
mat-raised-button routerLink="/" 

color="primary"> 
    <mat-icon>logout</mat-icon> 
    Logout
    <!-- alt person build fingerprint -->
</button>
<div style="padding: 0px 15px"></div>
</div>
<br>
<div class="content">
    <mat-card>
        <mat-form-field appearance="fill">
            <mat-label>Select</mat-label>
            <mat-select>
                <mat-option value="garage">Garage Repair</mat-option>
                <mat-option value="fence">Fencing</mat-option>
                <mat-option value="paint">Paint</mat-option>
                <mat-option value="popcorn">Popcorn Ceiling Removal</mat-option>
              </mat-select>
              <mat-hint align="end">Select Job Type ^</mat-hint>
          </mat-form-field>
    
    </mat-card>
  </div>
  <div style="display: flex; flex-direction: center; justify-content: center; align-items: center;">
    <br>
    <br>
    <br>
    <br>
    <button  
    mat-raised-button routerLink="/post" 
    color="primary"> 
        <mat-icon>post_add</mat-icon> 
        Post 
    </button>
  </div>