import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './auth/auth.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { ContractorprofileComponent } from './contractorprofile/contractorprofile.component';
import { PostjobComponent } from './postjob/postjob.component';
const routes: Routes = [
  {path: 'createaccount', component: SignUpComponent},
  {path: 'login', component: AuthComponent},
  {path: 'home', component: HomeComponent},
  {path: 'user', component: UserprofileComponent},
  {path: 'contractor', component: ContractorprofileComponent},
  {path: 'post', component: PostjobComponent},

  {path: '', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
