import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
Amplify.configure({
  Auth :{
    region: 'us-west-2',
    userPoolId: 'us-west-2_fzYPgwzTi',
    userPoolWebClientId: '7he5im2fft0vnqjp6jfac5831n',
    authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
})
const client = new AWSAppSyncClient({
  url: 'https://eqfxcxedrvfgfjm6syokm3ppj4.appsync-api.us-west-2.amazonaws.com/graphql',
  region: 'US-WEST-2',
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
});
Amplify.configure(client);
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
