import { Component, OnInit } from '@angular/core';
import { API, graphqlOperation } from 'aws-amplify';
import { APIService } from '../API.service';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Auth } from '@aws-amplify/auth';
@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit {
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];
  constructor(
    private apiService: APIService
  ) { }

  ngOnInit(): void {
    const allTodos = this.apiService.ListTodos();
    console.log(allTodos);
  }

}
