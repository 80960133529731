
<div class="toolbar"
role="banner">
<a href="https://www.propiris.com"> <img style="padding: 15px"
    width="100"
    alt="Angular Logo"
    src="assets/logo512.png" />
</a>
<div class="spacer"></div>
<button  
mat-raised-button routerLink="/" 

color="primary"> 
    <mat-icon>logout</mat-icon> 
    Logout
    <!-- alt person build fingerprint -->
</button>
<div style="padding: 0px 15px"></div>
</div>
<br>
<div class="content">
  <mat-card class="example-card">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Pending Jobs">

          <div class="card-content">
          <div class="sub-content">
          <mat-selection-list #shoes [multiple]="false">
              <mat-list-option *ngFor="let shoe of typesOfShoes" [value]="shoe">
              {{shoe}}
              </mat-list-option>
          </mat-selection-list>
          <br>
          </div>
          <div class="sub-content">
          <mat-divider [vertical]="true"></mat-divider>
          <mat-card>
              <mat-card-title>Job</mat-card-title>
              <mat-card-subtitle>Start</mat-card-subtitle>
              <mat-card-actions>
                <button mat-button>LIKE</button>
                <button mat-button>SHARE</button>
              </mat-card-actions>
            </mat-card>
            <br>
          </div>
          </div>

      </mat-tab>
      <mat-tab label="Booked Jobs">
          <mat-divider [vertical]="true"></mat-divider>
      </mat-tab>
      <mat-tab label="Completed Jobs">
          <mat-divider [vertical]="true"></mat-divider>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <div style="display: flex; flex-direction: center; justify-content: center; align-items: center;">
  <br>
  <br>
  <br>
  <br>
  <button  
  mat-raised-button routerLink="/post" 
  color="primary"> 
      <mat-icon>build</mat-icon> 
      Start a New Project 
  </button>
</div>

</div>