<div class="toolbar"
  role="banner">
  <a href="https://www.propiris.com"> <img style="padding: 15px"
      width="100"
      alt="Angular Logo"
      src="assets/logo512.png" />
  </a>
</div>

<br>
<br>
<br>

<div class="content">
  <mat-card>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Users Sign Up">
        <div class="example-container">
          <br>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your Name</mat-label>
              <input matInput
                placeholder="Jane Doe"
                [formControl]="username"
                required>
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your email</mat-label>
              <input matInput
                placeholder="startyourproject@example.com"
                [formControl]="email"
                required>
              <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter your Phone Number</mat-label>
              <input matInput
                placeholder="1234567891"
                [formControl]="phoneNumber">
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Create a password</mat-label>
              <input matInput
                [formControl]="password"
                [type]="hide ? 'password' : 'text'">
              <button mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <br>

          <div class="parent">
            <button mat-raised-button
              (click)="signUp()"
              color="primary">
              <mat-icon>fingerprint</mat-icon>
              Create Account
            </button>
          </div>
          <br>
        </div>
      </mat-tab>
      <mat-tab label="Contractors Sign Up">
        <div class="example-container">
          <br>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter Company Name</mat-label>
              <input matInput
                placeholder="Jane Doe"
                [formControl]="username"
                required>
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter Company email</mat-label>
              <input matInput
                placeholder="startyourproject@example.com"
                [formControl]="email"
                required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Enter Company Phone Number</mat-label>
              <input matInput
                placeholder="1234567891"
                [formControl]="phoneNumber"
                >
            </mat-form-field>
          </div>
          <div class="parent">
            <mat-form-field appearance="fill"
              style="width:50vw">
              <mat-label>Create a password</mat-label>
              <input matInput
                [formControl]="password"
                [type]="hide ? 'password' : 'text'">
              <button mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <br>

          <div class="parent">
            <button 
              (click)="signUp()"
              mat-raised-button
              color="primary">
              <mat-icon>fingerprint</mat-icon>
              Create Account
            </button>
          </div>
          <br>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <div class="parent">
    <div>
      Already have an account?<button routerLink="/login" mat-button color="primary">Login</button>
    </div>
  </div>
</div>

<!-- <amplify-authenticator>
  <div>
    Restaurants App
    <amplify-sign-out></amplify-sign-out>
  </div>
</amplify-authenticator> -->